const faqs = [
  {
    question: "¿Qué es el permiso de viaje ETIAS?",
    answer: (
      <div>
        <p>El Sistema de Información y Autorización de Viajes (SEIAV) pero conocido por sus siglas en inglés “ETIAS”, es el sistema para los ciudadanos de países que están libres de visa para visitar el Espacio Schengen. Uno de los objetivos principales es facilitar la identificación de posibles amenazas y riesgos asociados con las personas que quieran visitar cualquier país de los 27 que pertenezcan a la zona Schengen, entre ellos Alemania, España, Francia y Portugal.<br/><br/> Existen más de 50 países que necesitan solicitar el permiso de viaje ETIAS, entre ellos se encuentra Argentina y 14 países latinoamericanos para poder visitar la zona Schengen. La autorización ETIAS está diseñada para viajes de corta duración que pueden llegar hasta los 90 días. <br/><br/> El sistema ETIAS no es una visa, es una autorización de viaje para ciudadanos de terceros países. El sistema funcionará para permitir preseleccionar a los viajeros antes de que tomen un avión, de esta forma se espera conseguir un mayor control y seguridad respecto a los viajeros que entran al espacio Schengen sin visado.  </p> <br/>
      </div>
    ),
  },
  {
    question: "Cómo tramitar la autorización de viaje ETIAS en Argentina ",
    answer: (
      <div>
        <p>A partir de noviembre de 2023, todos los viajeros argentinos que deseen visitar Europa tendrán que solicitar en línea el permiso de viaje. Un ETIAS para argentinos será un proceso fácil y directo debido a que no requiere una visita a la embajada o consulado. <br/><br/> Si tu viaje es para realizar temas de negocio, de placer o de muy corta duración es necesario que cuentes con la autorización de viaje. ETIAS no será un permiso para trabajar, estudiar o vivir en alguno de los países del espacio Schengen.  <br/> <br/>Para solicitar el permiso ETIAS desde Argentina, cualquier solicitante tendrá que cumplir con los siguientes requisitos:
 </p> <br/>
        <p>- Pasaporte argentino válido por lo menos 3 meses más de la estadía prevista.</p>
        <p>- Dirección de correo electrónico para recibir el ETIAS aprobado.</p>
        <p>- Tarjeta de crédito o débito para pagar la cuota de ETIAS.</p> <br/>
        <p>Para entrar en un país de la zona Schengen, los argentinos necesitarán un pasaporte vigente, pero podrán viajar entre los países de Schengen utilizando una Cédula Nacional Argentina / DNI.</p>

      </div>
    ),
  },
  {
    question: "Asistencia al viajero para viajar a Europa",
    answer: (
      <div>
        <p>
        Si estás por organizar tu viaje a Europa ten en cuenta contratar un seguro de viaje, de esta forma podrás viajar seguro y tranquilo a cualquier país. Con ASSIST 365 puedes gestionar tu cobertura de manera más rápida, teniendo en cuenta los países, actividades y estado de las fronteras. 
        </p>
      </div>
    ),
  },
];

export default faqs;
